@import url('http://fonts.googleapis.com/css?family=Lato');
@import 'chart';
// @import "D:/sites/gavette.org/assets/css/variables/colors.scss";

body {
	background-color:#0d0d0d;
	transition: all 2000ms ease;
	&.results {
		// background-color:#4f81dd;
		background-color:#fff;
	}
}

#bg {
	width:100%;
	height:100%;
	top:0px;
	left:0px;
	position:fixed;
	z-index:-3;
	filter:opacity(0.5);
	background:url('https://www.toptal.com/designers/subtlepatterns/patterns/what-the-hex-dark.png');
	// background:url('../img/what-the-hex-dark2.png');
}

#blob {
	top:20vh;
	left:20vw;
	height:40vh;
	width:30vw;
	@media only screen and (max-width: 800px) {
		height:60vh;
	}
	position:fixed;
	z-index:-1;
	transform-origin: top right;
	transform: rotate(-20deg);
	canvas {
		transform: translateZ(0);
		filter:blur(5vmax);
	}
	// border:1px solid red;
	// overflow:hidden;
}
#blob2 {
	top:0vh;
	left:60vw;
	width:28vw;
	height:70vh;
	position:fixed;
	z-index:-2;
	transform-origin:top left;
	transform: rotate(30deg);
	canvas {
		transform: translateZ(0);
		filter:blur(5vmax);
	}
	// border:1px solid green;
}
.headline {
	width:100vw;
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
	h1 {
		color:#fff;
		font-family: 'Lato', sans-serif;
		font-size:6vw;
		font-weight:100;
		letter-spacing:6vw;
		text-align:center;
		text-transform:uppercase;
		text-shadow:6px 3px 0px rgba(0,0,0,0.8);
		transition: all 500ms ease-in;
		&:hover {
			text-shadow:-6px 3px 0px rgba(0,0,0,0.5);
		}
		
	}
	h2 {
		color:rgba(255,255,255,0.5);
		font-family:'Lato',sans-serif;
		font-size:1.8vw;
		font-weight:100;
		letter-spacing:1.4vw;
		position:absolute;
		width:100%;
		text-align:center;
		bottom:-30px;
		animation: 3s blurIn forwards 4s;
		filter:blur(1vw);
	}
}
header {
	position:absolute;
	top:0px;
	right:0px;
	h1 {
		font-family:'Lato', sans-serif;
		font-size:2vw;
		font-weight:100;
		letter-spacing:1vw;
		text-align:right;
		color:#fff;
		text-shadow:2px 1px 0px rgba(0,0,0,0.8);
		a {
			color:#fff;
			text-decoration:none;
			text-transform:uppercase;
		}
	}
}

@keyframes blurIn {
	0% {filter:blur(1vw);}
	100% {filter:blur(0);}
}
@for $i from 1 through 7 {
	.headline h1 div:nth-child(#{$i})		{
		filter:blur(1vw);
		display:inline-block;
		animation-name: blurIn;
		animation-duration:2.5s;
		animation-delay:(#{$i*0.4s});
		animation-fill-mode:forwards;
	}
}

.chart {
  position:absolute;
  // left:40vw;
  // top:30vh;
  // width:40vw;
	// height:30vh;
	width:80vw;
	max-width:800px;
	height:80vh;
	max-height:600px;
	left:10vw;
	top:10vh;

  ul {
		width:100%;
		height:100%;
		list-style:none;
		display:flex;
		align-items:center;
		justify-content:center;
    li {
			align-self:flex-end;
			float:left;
			margin:0.4vmin;
			// width:30px;
			width:100%;
      // height:100%;
			background-color:rgba(255,255,255,0.5);
			box-shadow:10px 10px 0px rgba(0,0,0,0.2);
			transform-origin:bottom center;
			transform:scaleY(0.1);
			animation: 0.6s chartBar cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    }
  }
}
@keyframes chartBar {
	0% {
		transform:scaleY(0.1);
	}
	100% {
		transform:scaleY(1);
	}
}
@for $i from 1 through 20 {
	.chart ul li:nth-child(#{$i}) {
		height:(#{$i*5%});
		animation-delay: #{$i * 50}ms;
	}
}